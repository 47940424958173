import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay } from "swiper/modules";

function AdsTop() {
  return (
    <Swiper
    className="bann-slide"
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
    >
      <SwiperSlide>
        <a
          href="https://myshops.az/az/Xiaomi-Redmi-Note-13-8GB-256GB-Midnight-Black"
          target="_blank"
        >
          <img
            className="adsTop"
            src="/assets/images/slidebann1 (1).jpg"
            alt="Sevdiyiniz nömrə sərfəli təklif ilə"
          />
        </a>
      </SwiperSlide>

      <SwiperSlide>
        <img
          className="adsTop"
          src="/assets/images/bannTop1.gif"
          alt="Sevdiyiniz nömrə sərfəli təklif ilə"
        />
      </SwiperSlide>

      <SwiperSlide>
        <a
          href="https://myshops.az/az/Xiaomi-Redmi-Note-13-8GB-256GB-Midnight-Black"
          target="_blank"
        >
          <img
            className="adsTop"
            src="/assets/images/slidebann1 (1).jpg"
            alt="Sevdiyiniz nömrə sərfəli təklif ilə"
          />
        </a>
      </SwiperSlide>

      <SwiperSlide>
        <img
          className="adsTop"
          src="/assets/images/bannTop1.gif"
          alt="Sevdiyiniz nömrə sərfəli təklif ilə"
        />
      </SwiperSlide>

      <SwiperSlide>
        <a
          href="https://myshops.az/az/Xiaomi-Redmi-Note-13-8GB-256GB-Midnight-Black"
          target="_blank"
        >
          <img
            className="adsTop"
            src="/assets/images/slidebann1 (1).jpg"
            alt="Sevdiyiniz nömrə sərfəli təklif ilə"
          />
        </a>
      </SwiperSlide>

      <SwiperSlide>
        <img
          className="adsTop"
          src="/assets/images/bannTop1.gif"
          alt="Sevdiyiniz nömrə sərfəli təklif ilə"
        />
      </SwiperSlide>
    </Swiper>
  );
}

export default AdsTop;
