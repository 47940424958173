import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Navbar() {

  const [user, setUser] = useState({});
  useEffect(() => {
    const userLS = localStorage.getItem('user');
    if (userLS) {
      const decodedUser = jwtDecode(JSON.parse(userLS));
      setUser(decodedUser.data[0]);
    } else {
      setUser()
    }
  },[localStorage.getItem('user')])

  const logOut = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('uuid')
    localStorage.setItem('log',JSON.stringify(0))
    window.location.reload()
  }

  const handleLinkClick = (event) => {
    event.preventDefault();
  };
  return (
    <div>
      {/* <div className="top-bar">
        <div className="container-fluid custom-container">
          <div className="row g-0 align-items-center">
            <div className="col-md-7">
              <ul className="list-inline mb-0 text-center text-md-start">
                <li className="list-inline-item">
                  <p className="fs-13 mb-0">
                    {" "}
                    <i className="mdi mdi-map-marker"></i> Məkan:{" "}
                    <a
                      href="def"
                      onClick={handleLinkClick}
                      className="text-dark"
                    >
                      Bakı
                    </a>
                  </p>
                </li>
                <li className="list-inline-item">
                  <ul className="topbar-social-menu list-inline mb-0">
                    <li className="list-inline-item">
                      <a
                        href="def"
                        onClick={handleLinkClick}
                        className="social-link"
                      >
                        <i className="uil uil-whatsapp"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="def"
                        onClick={handleLinkClick}
                        className="social-link"
                      >
                        <i className="uil uil-facebook-messenger-alt"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="def"
                        onClick={handleLinkClick}
                        className="social-link"
                      >
                        <i className="uil uil-instagram"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="def"
                        onClick={handleLinkClick}
                        className="social-link"
                      >
                        <i className="uil uil-envelope"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="def"
                        onClick={handleLinkClick}
                        className="social-link"
                      >
                        <i className="uil uil-twitter-alt"></i>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <ul className="list-inline mb-0 text-center text-md-end">
                <li className="list-inline-item py-2 me-2 align-middle">
                  <a
                    href="#signupModal"
                    className="text-dark fw-medium fs-13"
                    data-bs-toggle="modal"
                  >
                    <i className="uil uil-lock"></i>
                    Daxil ol
                  </a>
                </li>
                <li className="list-inline-item align-middle">
                  <div className="dropdown d-inline-block language-switch">
                    <button
                      type="button"
                      className="btn"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        id="header-lang-img"
                        src="assets/images/flags/russia.jpg"
                        alt="Header Language"
                        height="16"
                      />
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">

                      <a
                        href="def"
                        onClick={handleLinkClick}
                        className="dropdown-item notify-item language"
                        data-lang="ru"
                      >
                        <img
                          src="assets/images/flags/russia.jpg"
                          alt="lang"
                          className="me-1"
                          height="12"
                        />
                        <span className="align-middle">Russian</span>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
      <nav className="navbar navbar-expand-lg" id="navbar">
        <div className="container-fluid custom-container">
          <a
            className="navbar-brand text-dark fw-bold me-auto"
            href="/"
          >
            <img
              src="assets/images/logo/mynaz.svg"
              height="42"
              alt=""
              className="logo-dark"
            />
          </a>
          <div>
            <button
              className="navbar-toggler me-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-label="Toggle navigation"
            >
              <i className="mdi mdi-menu"></i>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav mx-auto navbar-center">
              <li className="p-1 nav-item dropdown dropdown-hover">
                <Link
                  className="btn btn-info"
                  to="/"
                >
                  Nömrələr
                  {/* <div className="arrow-down"></div> */}
                </Link>
                {/* <ul
                  className="dropdown-menu dropdown-menu-center"
                  aria-labelledby="productdropdown"
                 >
                  <li>
                    <a className="dropdown-item" href="blog.html">
                      Hamısı
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="blog-grid.html">
                      VIP nömrələr
                    </a>
                  </li>
                </ul> */}
              </li>


              <li className="p-1 nav-item">
                <Link to="/topup" className="btn btn-info">
                  Top Up
                </Link>
              </li>

              {/* <li className="p-1 nav-item">
                <Link to="/sima" className="btn btn-info sima-link">
                  SİMA
                </Link>
              </li> */}

              <li className="p-1 nav-item">
                <Link to="/about-us" className="btn btn-info">
                  Haqqımızda
                </Link>
              </li>

            </ul>
          </div>

          <ul className="header-menu list-inline d-flex align-items-center mb-0">
            {/* <li className="list-inline-item dropdown me-4">
              <a
                href="def"
                onClick={handleLinkClick}
                className="header-item noti-icon position-relative"
                id="notification"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="mdi mdi-bell fs-22"></i>
                <div className="count position-absolute">3</div>
              </a>
              <div
                className="dropdown-menu dropdown-menu-sm dropdown-menu-end p-0"
                aria-labelledby="notification"
              >
                <div className="notification-header border-bottom bg-light">
                  <h6 className="mb-1"> Notification </h6>
                  <p className="text-muted fs-13 mb-0">
                    You have 4 unread Notification
                  </p>
                </div>
                <div className="notification-wrapper dropdown-scroll">
                  <a
                    href="def"
                    onClick={handleLinkClick}
                    className="text-dark notification-item d-block active"
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-xs bg-primary text-white rounded-circle text-center">
                          <i className="uil uil-user-check"></i>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mt-0 mb-1 fs-14">
                          22 verified registrations
                        </h6>
                        <p className="mb-0 fs-12 text-muted">
                          <i className="mdi mdi-clock-outline"></i>{" "}
                          <span>3 min ago</span>
                        </p>
                      </div>
                    </div>
                  </a>
                  <a
                    href="def"
                    onClick={handleLinkClick}
                    className="text-dark notification-item d-block"
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <img
                          src="assets/images/user/img-02.jpg"
                          className="rounded-circle avatar-xs"
                          alt="user-pic"
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mt-0 mb-1 fs-14">James Lemire</h6>
                        <p className="text-muted fs-12 mb-0">
                          <i className="mdi mdi-clock-outline"></i>{" "}
                          <span>15 min ago</span>
                        </p>
                      </div>
                    </div>
                  </a>
                  <a
                    href="def"
                    onClick={handleLinkClick}
                    className="text-dark notification-item d-block"
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <img
                          src="assets/images/featured-job/img-04.png"
                          className="rounded-circle avatar-xs"
                          alt="user-pic"
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mt-0 mb-1 fs-14">
                          Applications has been approved
                        </h6>
                        <p className="text-muted mb-0 fs-12">
                          <i className="mdi mdi-clock-outline"></i>{" "}
                          <span>45 min ago</span>
                        </p>
                      </div>
                    </div>
                  </a>
                  <a
                    href="def"
                    onClick={handleLinkClick}
                    className="text-dark notification-item d-block"
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <img
                          src="assets/images/user/img-01.jpg"
                          className="rounded-circle avatar-xs"
                          alt="user-pic"
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mt-0 mb-1 fs-14">Kevin Stewart</h6>
                        <p className="text-muted mb-0 fs-12">
                          <i className="mdi mdi-clock-outline"></i>{" "}
                          <span>1 hour ago</span>
                        </p>
                      </div>
                    </div>
                  </a>
                  <a
                    href="def"
                    onClick={handleLinkClick}
                    className="text-dark notification-item d-block"
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <img
                          src="assets/images/featured-job/img-01.png"
                          className="rounded-circle avatar-xs"
                          alt="user-pic"
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mt-0 mb-1 fs-15">Creative Agency</h6>
                        <p className="text-muted mb-0 fs-12">
                          <i className="mdi mdi-clock-outline"></i>{" "}
                          <span>2 hour ago</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="notification-footer border-top text-center">
                  <a
                    className="primary-link fs-13"
                    href="def"
                    onClick={handleLinkClick}
                  >
                    <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                    <span>View More..</span>
                  </a>
                </div>
              </div>
            </li> */}
            {
              !(user) ? (


              <div>
                <a href="#simaScan" data-bs-toggle="modal">
                  <img
                    src="assets/images/logo/sima.svg"
                    height="70"
                    alt=""
                    className="logo-dark"
                  />

                </a>
              </div>
              ) : (
                <div className="user-block">
                  <p>{user.name}</p>
                  <span className="mdi mdi-logout sima-log" onClick={logOut}></span>
                </div>
              )
            }
            {/* <li className="list-inline-item dropdown">
              <a
                href="def"
                onClick={handleLinkClick}
                className="header-item"
                id="userdropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="assets/images/profile.jpg"
                  alt="mdo"
                  width="35"
                  height="35"
                  className="rounded-circle me-1"
                />{" "}
                <span className="d-none d-md-inline-block fw-medium">
                  Hi, Jansh
                </span>
              </a>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="userdropdown"
              >
                <li>
                  <a className="dropdown-item" href="manage-jobs.html">
                    Manage Jobs
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="bookmark-jobs.html">
                    Bookmarks Jobs
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="profile.html">
                    My Profile
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="sign-out.html">
                    Logout
                  </a>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </nav>

      <div
        className="modal fade"
        id="signupModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-5">
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="auth-content">
                <div className="w-100">
                  <div className="text-center mb-4">
                    <h5>Sign Up</h5>
                    <p className="text-muted">
                      Sign Up and get access to all the features of Jobcy
                    </p>
                  </div>
                  <form action="#" className="auth-form">
                    <div className="mb-3">
                      <label htmlFor="usernameInput" className="form-label">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="usernameInput"
                        placeholde="Enter your username"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="passwordInput" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="emailInput"
                        placeholde="Enter your email"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="emailInput" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="passwordInput"
                        placeholde="Password"
                      />
                    </div>
                    <div className="mb-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          I agree to the{" "}
                          <a
                            href="def"
                            onClick={handleLinkClick}
                            className="text-primary form-text text-decoration-underline"
                          >
                            Terms and conditions
                          </a>
                        </label>
                      </div>
                    </div>
                    <div className="text-center">
                      <button type="submit" className="btn btn-primary w-100">
                        Sign Up
                      </button>
                    </div>
                  </form>
                  <div className="mt-3 text-center">
                    <p className="mb-0">
                      Already a member ?{" "}
                      <a
                        href="sign-in.html"
                        className="form-text text-primary text-decoration-underline"
                      >
                        {" "}
                        Sign-in{" "}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
