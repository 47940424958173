import React from 'react'
import MainLayout from '../layout/MainLayout'

function AboutUs() {
  return (
    <MainLayout>

      <div className='container mt-5'>
        <div className='row'>
          <div className='col-lg-12 text-center'>
            <img src="assets/images/company.jpg" className='MGphoto' alt="MyGroup" />
          </div>
          <div className='col-lg-10 mx-auto mt-3'>
            
            <p>Dəyişməz keyfiyyətə, etik dəyərlərə sadiqliyimiz və müştəri məmnuniyyətinin davamlı axtarışı bizi daim gözləntiləri aşmağa və xidmət mükəmməlliyinin sərhəd tanımadığı bir dünya yaratmağa sövq edəcəkdir. </p>
            <p>Məqsədimiz şirkətin tanınması, yeni satış bazarlarının inkişafı, regionlarda layihələrin həyata keçirilməsi.</p>
            <h5>Güclü tərəflərimiz:</h5>
            <ul>
              <li>Fəaliyyət göstərdiyimiz sənaye və ya xidmət sektorlarında qabaqcıl olmaq;</li>
              <li>Müştərilərimiz üçün etibarlı tərəfdaş olmaq;</li>
              <li>Mükəmməlliyə sadiqlik və xidmət göstərilməsində yeni standartları müəyyən etmək;</li>
              <li>Fövqəladə xidmət göstərməyə sədaqətlə müştəri təcrübəsinə üstünlük vermək;</li>
              <li>Güclü etik prinsiplər və dəyərlər.</li>
            </ul>
            <p>Bizim əsas məqsədimiz həm maksimum rahatlıq səviyyəsini təmin etməklə, həm də çatdırılma və işlərinin sabitliyinə zəmanət verməklə müştərilərimizin ehtiyaclarını ödəmək üçün ən yaxşı dəyəri təklif etməkdir. Biznesinin ölçüsündən asılı olmayaraq hər bir müştəri bizim üçün vacibdir. Müştərilərimizin bizneslərinin inkişafı üçün ən yaxşı həllər tapmaq üçün bütün bazar və iqtisadi hərəkətlərin inkişafının mərkəzində olmağa çalışırıq.</p>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default AboutUs