import React from 'react'

function Ads(props) {
  return (
    <div className='ads-container'>
        <div className='ads'>
            <img src={props.src} alt='MyNumber' className='ad-image' />
        </div>
    </div>
  )
}

export default Ads