import axios from 'axios';

async function SendApi({ apiUrl, data }) {
  try {
    const response = await axios.post(apiUrl, data);
    // console.log('API-nın cavabı:', response.data);
    return response.data;
  } catch (error) {
    console.error('Sorğu zamanı səhv:', error);
    throw error;
  }
}

export default SendApi