import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import NumberGrid from "./NumberGrid";

export default function PlatiniumNumbers({ handleBuyClick }) {
  const [numbers, setNumbers] = useState([]);
  const [page, setPage] = useState(0);
  const [cookies, setCookie] = useCookies(["cart"]);



  useEffect(() => {
    if (page > 0) {
      fetch(`https://mynumber.az/api/allNum.php?mode=4&lim=6&off=${(page - 1) * 6}`)
        .then((response) => response.json())
        .then((data) => setNumbers((prevNumbers) => [...prevNumbers, ...data]))
        .catch((error) => console.error("Error fetching data: ", error));
    } else { setPage(1) }
  }, [page]);


  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleLinkClick = (event, number) => {
    event.preventDefault();
    const updatedCart = Array.isArray(cookies.cart) ? cookies.cart.filter(item => item.sim !== number.sim) : [];
    if (cookies.cart === undefined) {
      setCookie("cart", [number]);
    } else {
      if (updatedCart.length === cookies.cart.length) {
        updatedCart.push(number);
        const jobBox = event.target.closest('.job-box');
        if (jobBox) {
          jobBox.classList.add('bookmark-post');
        }
      }
      setCookie("cart", updatedCart);
    }
    setCookie("cart", updatedCart);
  };
  return (
    <div className="row ">
      {numbers.map((number, index) =>

        <div className="col-lg-4 col-md-6 mt-4" key={index}>
        <NumberGrid bgImage= {"platinum"} number={number} index={index} handleBuyClick={handleBuyClick} handleLinkClick={handleLinkClick} cookies={cookies} />
        </div>)}

      <div className="text-center mt-4 pt-2">
        <button className=" mc-bg-purple btn" onClick={handleLoadMore}>
          Daha Çox <i className="uil uil-arrow-right"></i>
        </button>
      </div>
      {/*end recent-jobs-tab*/}
    </div>
  );
}


