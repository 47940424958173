import React from 'react'

function Footer() {
  return (
    <div>
        <section className="bg-subscribe">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-6">
                <div className="text-center text-lg-start">
                  <h4 className="text-white">Təkliflərimizdən xəbardar ol</h4>
                  <p className="text-white-50 mb-0">
                    Abunə ol və yeniliklər ilk sizə gəlsin
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mt-4 mt-lg-0">
                  <form className="subscribe-form" action="#">
                    <div className="input-group justify-content-center justify-content-lg-end">
                      <input
                        type="text"
                        className="form-control"
                        id="subscribe"
                        placeholder="Email daxil edin"
                      />
                      <button
                        className="btn mc-bg-purple"
                        type="button"
                        id="subscribeSubmit"
                      >
                        Abunə ol
                      </button>
                    </div>
                  </form>
                  {/*end form*/}
                </div>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
          {/*end container*/}
          <div className="email-img d-none d-lg-block">
            <img
              src="assets/images/subscribe.png"
              alt=""
              className="img-fluid"
            />
          </div>
        </section>
        {/* END SUBSCRIBE */}

        {/* START FOOTER */}
        
        {/* START FOOTER-ALT */}
        <div className="footer-alt">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className="text-white-50 text-center mb-0">
                © 2024 Copyright MyNumber - Sayt müəllif hüquqları ilə qorunur. MySoft tərəfindən hazırlanmışdır
                </p>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
          {/*end container*/}
        </div>
        {/* END FOOTER */}
    </div>
  )
}

export default Footer