import React from 'react'
import MainLayout from '../layout/MainLayout'

function Sima() {
  return (
    <MainLayout>
      <div className='container sima-container'>
        <div className='row'>

          <div className="col-md-4">
            <div className="card mt-4">
              <img className="card-img-top img-fluid" src="assets/images/sima-thumb2.png" alt="Sima" />
              <div className="card-body">
                <h5 className='card-title mb-2'>Biz kimik?</h5>
                <p class="card-text">
                  Rəqəmsal İnkişaf və Nəqliyyat Nazirliyinin tabeliyində fəaliyyət göstərən “AzInTelecom” MMC tərəfindən yaradılan SİMA bulud əsaslı yeni nəsil rəqəmsal imzadır.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card mt-4">
              <img className="card-img-top img-fluid" src="assets/images/sima-thumb1.png" alt="Sima" />
              <div className="card-body">
                <h5 className='card-title mb-2'>Sima nədir?</h5>
                <p class="card-text">
                  SİMA – bulud, açıq açar və üztanıma texnologiyalarına əsaslanan yeni nəsil gücləndirilmiş rəqəmsal imzadır.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card mt-4">
              <img className="card-img-top img-fluid" src="assets/images/sima-thumb3.png" alt="Sima" />
              <div className="card-body">
                <h5 className='card-title mb-2'>SİMA nə üçündür?</h5>
                <p class="card-text">
                  Mobil tətbiq əsaslı SİMA əlavə vasitələrə ehtiyac olmadan, istənilən yerdən və istənilən zaman daha rahat şəkildə elektron xidmətlər əldə etməyə imkan verir.
                </p>
              </div>
            </div>
          </div>


        </div>
        <div className='row mt-4'>

          <div className='col-lg-6'>
            <svg width="93" height="28" viewBox="0 0 93 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.6501 0H24.3707V4.27731H31.6501V0Z" fill="#1D204F"></path><path d="M4.2294 18.7818C6.31623 20.7743 9.0917 21.8843 11.977 21.8804C13.3463 21.8804 14.6427 21.4098 14.6427 20.4687C14.6427 19.5276 13.6334 19.0571 11.2547 18.6289C7.29269 17.9089 1.2367 16.9819 1.2367 10.9542C1.2367 6.88156 4.62466 3.17126 11.2194 3.17126C15.1462 3.17126 18.6777 4.28882 21.4163 6.52158L17.5248 11.6741C15.4356 10.0907 12.7322 9.29783 10.8218 9.29783C9.02196 9.29783 8.62434 9.94484 8.62434 10.5589C8.62434 11.5 9.59838 11.82 12.1535 12.2529C16.1179 12.9375 21.9927 14.0904 21.9927 19.6382C21.9927 24.9366 18.0636 27.9999 11.6147 27.9999C6.49746 27.9999 3.00126 26.4847 0.441467 24.179L4.2294 18.7818Z" fill="#1D204F"></path><path d="M24.3707 27.5744V6.12903H31.6501V27.5744H24.3707Z" fill="#1D204F"></path><path d="M35.5429 23.6381V27.5742H39.4814L35.5429 23.6381Z" fill="#1D204F"></path><path d="M55.1871 27.5738H62.4665V15.6571L55.1871 22.9365V27.5738Z" fill="#1D204F"></path><path d="M84.3698 27.5739L83.4287 24.4754H74.2012L73.2601 27.5739H65.3784L74.2435 3.53821H83.3957L92.2609 27.5739H84.3698ZM78.8196 9.95181L76.081 18.4217H81.5582L78.8196 9.95181Z" fill="#1D204F"></path><path d="M62.4678 0.00941123V10.1686L55.1884 17.4504L45.0645 27.5743L39.9755 22.4853L39.9778 22.4829L35.5429 18.048V7.87702L35.5453 7.87231L45.0669 17.3939L62.4584 0L62.4678 0.00941123Z" fill="#00B04E"></path></svg>
            <p className='sima-text'>SİMA mobil tətbiqi ilə dövlət və özəl qurumlara aid 100-dən çox müxtəlif xidmətdən istifadə edə bilərsiniz</p>
              <p className='sima-text'>Rəqəmsal İnkişaf və Nəqliyyat Nazirliyinin tabeliyində fəaliyyət göstərən “AzInTelecom” MMC tərəfindən yaradılan SİMA bulud əsaslı yeni nəsil rəqəmsal imzadır. 11.02.2022 tarixdə ictimaiyyətə təqdim olunan SİMA hər kəs üçün əlçatan, vətəndaşlar üçün isə tamamilə ödənişsizdir. SİMA daim xidmət sahələrini genişləndirməyə, istifadəçilərinə sürətli, keyfiyyətli və faydalı xidmət göstərməyə davam edir.</p>
          </div>

          <div className='col-lg-6'>
            <img src="assets/images/img24.jpg" alt="Sima" />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Sima