import "./App.css";
import Home from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TopUp from "./pages/TopUp";
import Sima from "./pages/Sima";
import AboutUs from "./pages/AboutUs";
import Tracking from "./pages/Tracking";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/nomreler" element={<Home />} />
          <Route path="/online-nomre" element={<Home />} />
          <Route path="/azercell-nomreler" element={<Home />} />
          <Route path="/nomre-satisi" element={<Home />} />
          <Route path="/onlayn-nomre-satisi" element={<Home />} />
          <Route path="/onlayn-nomre-sifarisi" element={<Home />} />
          <Route path="/onlayn-nomre-almaq" element={<Home />} />

          <Route path="/topup" element={<TopUp />} />
          <Route path="/sima" element={<Sima />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/track/:fin/:oId" element={<Tracking />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
