import React from 'react'
import MainLayout from '../layout/MainLayout'

function TopUp() {
  return (
    <MainLayout>

        <iframe title='Azercell' id="topUp" className="top-up"  src="https://smartpay.az/payment/service/421?iframe=true&lang=az"></iframe>
    </MainLayout>
  )
}

export default TopUp