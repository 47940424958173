import { faCheck, faFileCircleCheck, faMotorcycle, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import NumberGrid from '../components/NumberGrid';

function Tracking() {
    const navigate = useNavigate();
    let { fin, oId } = useParams();
    const [data, setData] = useState(null);
    const [number, setNumber] = useState(null);
    const [status,setStatus] = useState(null);
    const statusTextObjects = [
        { status: '0', text: "Sifarişiniz gözləmədə" },
        { status: '1', text: "Sifarişiniz təsdiqləndi" },
        { status: '2', text: "Sifarişiniz hazırlanır" },
        { status: '3', text: "Sifarişiniz kuriyerdə" },
        { status: '4', text: "Sifarişiniz tamamlandı" }
    ];
    
    function getStatusText(status) {
        const statusObject = statusTextObjects.find(obj => obj.status === status);
        return statusObject ? statusObject.text : "Status tapılmadı";
    }

    useEffect(() => {
        const fetchData = async () => {
            if (fin && fin.length === 7 && oId) {
                try {
                    const response = await axios.post('https://mynumber.az/api/tracking.php', {
                        id: oId,
                        fin: fin
                    });
                    setData(response.data[0]);
                    setNumber(response.data[0].numberData)
                } catch (err) {
                    setData(null);
                    navigate('/');
                }
            }else {
                navigate('/');
            }
        };

        fetchData();
    }, [fin, oId]);

    useEffect(() => {
        const fetchStatus = async () => {
            if (fin && fin.length === 7 && oId) {
                try {
                    const response = await axios.post('https://mynumber.az/api/trackStatus.php', {
                        id: oId,
                        fin: fin
                    });
                    
                    setStatus(response.data[0].tracking);
                } catch (err) {
                    setStatus(null);
                }
            }
        };
        fetchStatus();

        const interval = setInterval(fetchStatus, 500);

        // Cleanup function for clearing the interval
        return () => clearInterval(interval);
    },[fin, oId])
    return (
        <div className="container">
            {data &&
                <article className="card">
                    <div className="card-body">
                        <h6>Sifariş ID: {data.id} </h6>
                        <article className="card">
                            <div className="card-body row">
                                <div className="col"> <strong>Təxmini çatdırılma vaxtı:</strong> <br />{data.shipDate} </div>
                                <div className="col"> <strong>Status:</strong> <br /> {status && getStatusText(status)} </div>
                                <div className="col"> <strong>Sifariş edən:</strong> <br /> {data.ad} </div>
                            </div>
                        </article>
                        <div className="track">
                        <div className={`step ${status > 0 ? 'active' : ''}`}> 
                                <span className="icon"> <FontAwesomeIcon icon={faCheck} /> </span> 
                            </div>
                            <div className={`step ${status > 1 ? 'active' : ''}`}> 
                                <span className="icon"> <FontAwesomeIcon icon={faWandMagicSparkles} /></span> 
                            </div>
                            <div className={`step ${status > 2 ? 'active' : ''}`}> 
                                <span className="icon"> <FontAwesomeIcon icon={faMotorcycle} /> </span> 
                            </div>
                            <div className={`step ${status > 3 ? 'active' : ''}`}> 
                                <span className="icon"> <FontAwesomeIcon icon={faFileCircleCheck} /> </span> 
                            </div>
                        </div>
                        <hr />
                        <ul className="row">
                            <li className="col-md-4">
                               {number && <NumberGrid number={number} />}
                            </li>
                        </ul>
                        <hr />
                        <Link to="/" className="btn btn-warning" data-abc="true"> <i className="fa fa-chevron-left" /> Ana səhifə</Link>
                    </div>
                </article>
            }
        </div>

    )
}

export default Tracking